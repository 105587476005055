<template>
  <div class="upload">
    <div class="searchBtn">
      <el-select v-model="uploadInfo.classify" placeholder="请选择分类" size="small">
        <el-option v-for="item in attachmentTypeList" :key="item.id" :label="item.title" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" size="small" @click="toRouter('attachmentClassify')">分类管理</el-button>
    </div>
    <!-- 上传 -->
    <el-upload class="upload-demo" :on-success="handleAvatarSuccess"  drag :action="$store.state.requestAPI + `/attachment/upload?ct=${APIinfo.ct}&sign=${APIinfo.sign}&jpressAppId=${APIinfo.jpressAppId}`" :data="{
      'id': uploadInfo.classify, ...APIinfo
    }" multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
  </div>
</template>

<script>
import { getAttachmentTypeListAPI } from '@/api/attachment/attachmentType'
export default {
  data() {
    return {
      uploadInfo: {
        classify: 0
      },
      attachmentTypeList: [],
      APIinfo:{}
    }
  },
  methods: {
    // 跳转到分类管理
    toRouter(val) {
      this.$router.push({
        name: val
      })
    },
     // 上传图片
     async handleAvatarSuccess(res, file) {
            console.log(res)
            if (res.code === 200) {
                // this.basicRuleForm.logo = this.$store.state.requestAPI +  res.data.path;
                // this.submitImg();

            } else {
                this.$message({
                    showClose: true,
                    message: '操作失败！' + res.msg,
                    type: 'error'
                });
            }

            // this.imageUrl = URL.createObjectURL(file.raw);
        },
    // 获取附件分类列表
    async getTypeList() {
      let info = { pageSize: 100000, pageNum: 1 }
      const res = await getAttachmentTypeListAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.attachmentTypeList = [{id: 0, title: '默认'}, ...res.data.list];
        // this.paging.total = res.data.total;
      }
    },
    uploadFile() {
      this.$store.dispatch('getAPI');
      this.APIinfo = this.$store.state.APIinfo;
      
    }
  },
  created() {
    this.getTypeList();
    this.uploadFile();
  }
}
</script>

<style lang="scss" scoped>
.upload {}
</style>